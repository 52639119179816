
import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Navigation() {

  const [language, setLanguage] = useState("english");
  
  return (
   <>
    <div className="d-block d-lg-none">
      <div  style={{ width: "100%", display: "flex", flexDirection: "row",justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "row", height: "75px"}}>
          <img 
            src={require("../assets/img/logo_id360.png")} alt="downward arrow"
            style={{ height: "35px", margin: "20px 10px 0px 20px", border: "0.5px black solid", borderRadius: "20px"}}
            className="img pointer"
            onClick={()=> window.open("https://www.linkedin.com/pulse/four-reasons-should-consider-using-video-cvs-idorenyin-antiaobong/", "_blank")}
          />
          <div style={{ textAlign: "left", margin: "auto"}}>
            <p style={{ margin: 0, padding: 0, lineHeight: "14px", fontSize: "14px"}}>
              InmuebleData
            </p>
            <p style={{ margin: 0, padding: 0, lineHeight: "14px", fontSize: "14px"}}>
              360.com
            </p>
          </div>
         </div>
        
        
          <div style={{ display: "flex", flexDirection: "row", margin: "20px"}}>
            <div 
              style={{ width: "35px", height: "35px", border: (language === "spanish") ? "0.5px rgb(100,100,100) solid" : null , borderRadius: "20px"}}
              onClick={() => setLanguage("spanish")}
            >
              <p style={{ fontSize: "14px", margin: "auto", paddingTop: "7px" }}>ES</p>
            </div>
            <div 
              style={{ width: "35px", height: "35px", border: (language === "english") ? "0.5px rgb(100,100,100) solid" : null , borderRadius: "20px"}}
              onClick={() => setLanguage("english")}
            >
              <p style={{ fontSize: "14px", margin: "auto", paddingTop: "7px" }}>EN</p>
            </div>
            
            
          </div>
    
     
    
      </div>
      
       
      </div>
    </>
    
  );
}

export default Navigation;
