import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCDH-YMoiJKY8dIqaT1Ca1yZZzyB2owPgI",
  authDomain: "inmuebledata360.firebaseapp.com",
  databaseURL: "https://inmuebledata360-default-rtdb.firebaseio.com",
  projectId: "inmuebledata360",
  storageBucket: "inmuebledata360.appspot.com",
  messagingSenderId: "1046550987828",
  appId: "1:1046550987828:web:aaff57fb1f200e9469f661",
  measurementId: "G-7SRXFN7SJ0"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Firestore
const db = firebase.firestore();

export { db };