import { Col, Row, Card, Image, Button, Container, Tooltip, OverlayTrigger, Navbar } from '@themesberg/react-bootstrap';


function ReportDetails() {

  return (
   <div style={{ backgroundColor: "white", borderRadius: "20px", marginTop: "0px", zIndex: "1000", marginBottom: "60px"}}>
    <p style={{ textAlign: "left", fontSize: "24px", paddingLeft: "25px", fontWeight: "700", paddingTop: "25px"}}>Report includes:</p>
      <div style={{ width: "100%", padding: "20px", paddingBottom: "0px", display: "flex", flexDirection: "row"}}> 
        {/* this is one row of two tiles */}
        <div style={{flex: 1, padding: "5px"}}>
          <div style={{ height: "200px", textAlign: "center", padding: "20px", border: "0.5px rgba(100,100,100,1) solid", borderRadius: "20px"}}>
          <img 
              src={require("../assets/img/report_details_item1.png")} alt="report details item 1"
              style={{ height: "45px", marginBottom: "20px" }}
          />
            <p style={{ fontSize: "16px"}}>Purchase price</p>
            <p style={{ fontSize: "12px"}}>Historic development, various sources, market trends</p>
          </div>
          
        </div>
        <div style={{flex: 1, padding: "5px"}}>
          <div style={{ height: "200px", textAlign: "center", padding: "20px", border: "0.5px rgba(100,100,100,1) solid", borderRadius: "20px"}}>
          <img 
              src={require("../assets/img/report_details_item1.png")} alt="report details item 1"
              style={{ height: "45px", marginBottom: "20px" }}
          />
            <p style={{ fontSize: "16px"}}>Rental price</p>
            <p style={{ fontSize: "12px"}}>Historic development, various sources, market trends</p>
          </div>
          
        </div>
      </div>
      <div style={{ width: "100%", padding: "20px", paddingTop: "0px", paddingBottom: "0px", display: "flex", flexDirection: "row"}}> 
        {/* this is one row of two tiles */}
        <div style={{flex: 1, padding: "5px"}}>
          <div style={{ height: "200px",textAlign: "center", padding: "20px", border: "0.5px rgba(100,100,100,1) solid", borderRadius: "20px"}}>
          <img 
              src={require("../assets/img/report_details_item1.png")} alt="report details item 1"
              style={{ height: "45px", marginBottom: "20px" }}
          />
            <p style={{ fontSize: "16px"}}>Services</p>
            <p style={{ fontSize: "12px"}}>Bars & Restaurants, points of interest</p>
          </div>
          
        </div>
        <div style={{flex: 1, padding: "5px"}}>
          <div style={{ height: "200px",textAlign: "center", padding: "20px", border: "0.5px rgba(100,100,100,1) solid", borderRadius: "20px"}}>
            <img 
                src={require("../assets/img/report_details_item1.png")} alt="report details item 1"
                style={{ height: "45px", marginBottom: "20px" }}
            />
            <p style={{ fontSize: "16px"}}>Noise Pollution</p>
            <p style={{ fontSize: "12px"}}>Historic development, various sources, market trends</p>
          </div>
          
        </div>
      </div>
      <div style={{ width: "100%", padding: "20px", paddingTop: "0px", display: "flex", flexDirection: "row"}}> 
        {/* this is one row of two tiles */}
        <div style={{flex: 1, padding: "5px"}}>
          <div style={{ height: "200px",textAlign: "center", padding: "20px", border: "0.5px rgba(100,100,100,1) solid", borderRadius: "20px"}}>
          <img 
              src={require("../assets/img/report_details_item1.png")} alt="report details item 1"
              style={{ height: "45px", marginBottom: "20px" }}
          />
            <p style={{ fontSize: "16px"}}>Crime Rates</p>
            <p style={{ fontSize: "12px"}}>Historic development, various sources, market trends</p>
          </div>
          
        </div>
        <div style={{flex: 1, padding: "5px"}}>
          <div style={{ height: "200px",textAlign: "center", padding: "20px", border: "0.5px rgba(100,100,100,1) solid", borderRadius: "20px"}}>
          <img 
              src={require("../assets/img/report_details_item1.png")} alt="report details item 1"
              style={{ height: "45px", marginBottom: "20px" }}
          />
            <p style={{ fontSize: "16px"}}>Economic Data</p>
            <p style={{ fontSize: "12px"}}>Historic development, various sources, market trends</p>
          </div>
          
        </div>
      </div>
      
  </div>
  );
}

export default ReportDetails;
