
import React, { useState } from "react";
import { Col, Row, Card, Image, Button, Container, Tooltip, OverlayTrigger, Navbar } from '@themesberg/react-bootstrap';


function CookieOverlay ({ handleConsent }) {
    const [showCookieOverlay, setShowCookieOverlay] = useState(true);

    return (
        <>
         {
                (showCookieOverlay) ?
                <div style={{ position: "fixed", top: "0px", left: "0px", width: "100vw", height: "100vh", backgroundColor: "rgba(0,0,0,0.2)", zIndex: "2000" }}>
                    <Row style={{ position: "fixed", left: "0px", bottom: "0px", width: "100vw", backgroundColor: "rgba(0,0,0,0.8)", display: "flex", flexDirection: "row", margin: "0px", padding: "0px"}}>
                        <Col lg={6} md={6} sm={12}>
                            <p className="fontSmall fontPoppins" style={{ color: "white", fontSize: "12px", margin: "20px", lineHeight: "18px", textAlign: "left"}}>Hello! We only use essential cookies. By clicking "Continue" you agree to all of them. Read our <span style={{ color: "white"}}><a style={{ color: "white"}} href="https://inmuebledata360.com/privacy">Privacy Agreement</a></span>.</p>
                        </Col>
                        <Col lg={6} md={6} sm={12} className="d-flex align-items-center justify-content-end" style={{ padding: "10px"}}>
                            <div style={{ display: "flex", flexDirection: "row", height: "100%", height: "60px" }} className="d-flex justify-content-end cursor">
                                
                                    <p 
                                    style={{
                                        fontSize: "20px",
                                        lineHeight: "20px",
                                        textAlign: "center",
                                        width: "140px",
                                        padding: "10px",
                                        borderRadius: "20px",
                                        margin: "0px",
                                        height: "40px",
                                        color: "black",
                                        fontWeight: "700",
                                        backgroundColor: "white",
                                    }}
                                    onClick={() =>{                                  
                                        handleConsent(true);
                                        console.log("Continue...");
                                    }} 
                                    className="pointer">
                                        Okay
                                    </p>
                            </div>
                        </Col>
                    </Row>
                </div>
                :
                null
            }
        </>
    )
}

export default CookieOverlay;
