import { Col, Row, Card, Image, Button, Container, Tooltip, OverlayTrigger, Navbar } from '@themesberg/react-bootstrap';


function Services() {

  return (
   <div style={{ backgroundColor: "white", borderRadius: "20px", marginTop: "-30px", zIndex: "1000", marginBottom: "60px"}}>
    <p style={{ textAlign: "left", fontSize: "24px", paddingLeft: "25px", fontWeight: "700", paddingTop: "25px"}}>Our service:</p>
    <p style={{ textAlign: "left", fontSize: "16px", paddingLeft: "25px", paddingRight: "20px", fontWeight: "200" }}>In the dynamic world of Barcelona <span style={{ fontWeight: "700"}}>real estate</span>, informed decision-making is paramount. We deliver comprehensive, data-rich reports <span style={{ fontWeight: "700"}}>tailored</span> to individual properties of interest. Our mission is to empower potential buyers with detailed, accurate, and relevant <span style={{ fontWeight: "700"}}>information</span>, enabling strategic investment decisions.</p>
    <img 
        src={require("../assets/img/services_1.png")} alt="report details item 1"
        style={{ height: "100px", marginBottom: "20px" }}
    />
    <p style={{ textAlign: "left", fontSize: "16px", paddingLeft: "25px", fontWeight: "700", paddingTop: "25px"}}>Data quality</p>
    <p style={{ textAlign: "left", fontSize: "16px", paddingLeft: "25px", paddingRight: "20px", fontWeight: "200" }}>We craft detailed, multi-sourced reports, leveraging advanced analytical tools to distill key insights for your decision-making process. Armed with this robust and trustworthy information, you're empowered to make the most informed choices for your substantial financial investments in real estate</p>
  
    <img 
        src={require("../assets/img/services_2.png")} alt="report details item 1"
        style={{ height: "100px", marginBottom: "20px", marginTop: "20px" }}
    />
    <p style={{ textAlign: "left", fontSize: "16px", paddingLeft: "25px", fontWeight: "700", paddingTop: "25px"}}>Travel distance map</p>
    <p style={{ textAlign: "left", fontSize: "16px", paddingLeft: "25px", paddingRight: "20px", fontWeight: "200" }}>We generate a tailored heat-map revealing the travel time to essential points of interest for you – like work, family, and friends. This feature offers a unique perspective on your daily commute and social connectivity, and ensuring your next property choice is not just a house, but a conveniently located home in the heart of your world.</p>
  
    <img 
        src={require("../assets/img/services_3.png")} alt="report details item 1"
        style={{ height: "100px", marginBottom: "20px", marginTop: "20px" }}
    />
    <p style={{ textAlign: "left", fontSize: "16px", paddingLeft: "25px", fontWeight: "700", paddingTop: "25px"}}>Quantitative and qualitative analysis</p>
    <p style={{ textAlign: "left", fontSize: "16px", paddingLeft: "25px", paddingRight: "20px", fontWeight: "200" }}>We fuse big data analysis and nuanced local insights specific to Barcelona which allows us to provide you with uniquely tailored feedback on individual properties. Experience a comprehensive understanding where numbers and narratives converge, offering you a complete picture for your investment decision.</p>
  
    <img 
        src={require("../assets/img/services_4.png")} alt="report details item 1"
        style={{ height: "100px", marginBottom: "20px", marginTop: "20px" }}
    />
 
        <p style={{ textAlign: "center", fontSize: "8px", marginLeft: "25px", marginRight: "20px", padding: "0px 5px", fontWeight: "200", paddingTop: "3px", paddingBottom: "3px", marginBottom: "0px", backgroundColor: "#404040", color: "white", width: "70px", borderRadius: "20px" }}>Coming Soon</p>



    <p style={{ textAlign: "left", fontSize: "16px", paddingLeft: "25px", fontWeight: "700" }}>Dashboard</p>
    <p style={{ textAlign: "left", fontSize: "16px", paddingLeft: "25px", paddingRight: "20px", fontWeight: "200" }}>A web portal where you can upload and manage multiple reports. This user-friendly platform not only facilitates easy comparison but also gives you the ability to share insights with others.</p>
  


  </div>
  );
}

export default Services;
