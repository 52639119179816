import Accordion from './Accordion';

function Faq() {

    const questionsAnswers = [
        {
            question: 'What types of properties do your reports cover in Barcelona?',
            answer: 'Our reports cover residential real estate properties in the city of Barcelona.'
        },
        {
            question: 'How up-to-date is the data in your reports?',
            answer: 'This depends on the data source. Most data sets are update on a weekly, monthly, quarterly or yearly basis. Additionally. for the data we collect we try to update on a monthly basis (if possible).'
        },
        {
            question: 'Can I request a report for a property outside of Barcelona city limits?',
            answer: 'Currently, our primary focus is on properties within Barcelona city limits. Please contact us direactly at reports@inmuebledata360.com if you would like a report in a different area.'
        },
        {
            question: 'How do you ensure the accuracy and reliability of your data?',
            answer: 'We source our data from trusted and verified channels, including government databases, real estate portals, and market analyses, ensuring high accuracy and reliability.'
        },
        {
            question: 'How long does it take to receive a report after ordering?',
            answer: 'Reports are typically processed and delivered within 5 - 10 minutes after the order is placed.'
        },
        {
            question: 'Is there any support available if I have questions about my report?',
            answer: 'Absolutely! Our customer support team is available to assist you with any questions or clarifications about your report. Please contact us at help@inmuebledata360.com.'
        },
        {
            question: 'What payment methods do you accept for purchasing reports?',
            answer: 'We accept credit/debit cards. We use a third party service called Stripe to process our payments.'
        },
        {
            question: 'Can I share the reports with my real estate agent or financial advisor?',
            answer: 'Yes, you are free to share the reports with your real estate agent or financial advisor to assist in your property decision-making process.'
        }
    ];

  return (
   <div style={{ backgroundColor: "#404040", borderRadius: "20px", marginTop: "-30px", zIndex: "1000", padding: "25px", paddingTop: "30px", paddingBottom: "30px", marginBottom: "0px"}}>
    <p style={{ textAlign: "left", fontSize: "24px", fontWeight: "700", color: "white"}}>Frequently asked questions</p>
    <div>
            <Accordion questionsAnswers={questionsAnswers} />
            
        </div>
           
       
    </div>
  );
}

export default Faq;


