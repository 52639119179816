import { useEffect, useState } from 'react';
import backgroundImage from '../assets/img/barcelona.png';


function CallToAction({ address, setAddress }) {


  return (
   <div style={{ backgroundColor: "#404040", borderRadius: "20px", marginTop: "-30px", zIndex: "1000", padding: "25px"}}>
    <p style={{ textAlign: "left", fontSize: "24px", fontWeight: "700", paddingTop: "25px", color: "white"}}>Your Address, Your Insights</p>
    <p style={{ textAlign: "left", fontSize: "16px", fontWeight: "300", paddingTop: "25px", color: "white"}}>Enter your address, see your customized report: Discover real estate intelligence tailored specifically for you.</p>
    <div style={{ marginBottom: "20px" }}>
                <p style={{ fontSize: "24px", fontWeight: "700", margin: 0, padding: 0, marginBottom: "10px", textAlign: "left", color: "white"}}>Get a personalised report</p>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <input value={address} onChange={(event) => setAddress(event.target.value)} style={{ height: "40px", borderRadius: "50px", border: "none", paddingLeft: "20px", fontSize: "16px", width: "100%", marginRight: "20px"}} />
                    <div>
                        <img 
                            src={require("../assets/img/submit_green.png")} alt="downward arrow"
                            style={{ height: "40px" }}
                            className="img pointer"
                            onClick={()=> window.open("https://www.linkedin.com/pulse/four-reasons-should-consider-using-video-cvs-idorenyin-antiaobong/", "_blank")}
                        />
                    </div>
                </div>
                <p style={{ fontSize: "10px", marginTop: "5px", fontWeight: "200", color: "white", textAlign: "left"}}>*Address submission implies agreement to our <a style={{ color: "white"}} href="https://inmuebledata360.com/terms-and-conditions">Terms and Conditions.</a></p>

                
            </div>
            <div>
                <div style={{ backgroundColor: "white", width: "220px", height: "340px", margin: "50px auto",  }}>
                <p style={{ position: "relative", top: "2px", right: "2px", fontSize: "2px", textAlign: "right", width: "100%", paddingRight: "10px", paddingTop: "10px"}}>InmuebleData360.com - January 2024</p>
                <div style={{ width: "100%", textAlign: "left"}}>
                    <img 
                        src={require("../assets/img/logo_id360.png")} alt="downward arrow"
                        style={{ height: "25px", border: "0.5px black solid", borderRadius: "20px", marginLeft: "15px"}}
                        className="img pointer"
                        onClick={()=> window.open("https://www.linkedin.com/pulse/four-reasons-should-consider-using-video-cvs-idorenyin-antiaobong/", "_blank")}
                    />
                </div>
                <p style={{ fontWeight: "200", fontSize: "10px", textAlign: "left", paddingLeft: "15px", paddingTop: "40px", marginBottom: "0px"}}>Property Data Report Barcelona</p>
                <p style={{ fontWeight: "700", fontSize: "12px", textAlign: "left", paddingLeft: "15px", whiteSpace: "nowrap", overflow: "hidden", marginRight: "10px" }}>{address}</p>
                <div style={{
                    position: 'relative',
                    width: '100%',
                    height: '150px',
                   
                    overflow: "hidden",
                    
                    
                }}>
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '150px',
                            backgroundImage: `url(${backgroundImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: "top",
                            filter: "grayscale(100%)"
                        }}
                    />  
                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#2F6526',
                        opacity: "70%"
                        }}
                    />
                </div>
                <p style={{ margin: 0, padding: 0, fontSize: "2px", fontWeight: "700", textAlign: "left", width: "100%", marginLeft: "10px", marginTop: "20px"}}>Disclaimer</p>
                <p style={{ margin: 0, padding: 0, fontSize: "2px", fontWeight: "200", textAlign: "left", width: "100%", marginLeft: "10px", marginTop: "0px"}}>This is an example report and is just intended to visualise the product </p>

            </div>
        </div>
    </div>
  );
}

export default CallToAction;


//import backgroundImage from '../assets/img/barcelona.png';