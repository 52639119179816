import { Col, Row, Card, Image, Button, Container, Tooltip, OverlayTrigger, Navbar } from '@themesberg/react-bootstrap';
import backgroundImage from '../assets/img/barcelona_1.jpg';
import backgroundImage2 from '../assets/img/barcelona_3.jpg';
import backgroundImage3 from '../assets/img/barcelona_2.jpg';


function AdditionalReports({ log, setLog }) {

  return (
   <div style={{ backgroundColor: "white", borderRadius: "20px", marginTop: "-50px", zIndex: "1000", marginBottom: "60px"}}>
    <p style={{ textAlign: "left", fontSize: "24px", paddingLeft: "25px", fontWeight: "700", paddingTop: "25px"}}>Additional reports</p>
    <div style={{ padding: "25px", overflow: "hidden" }}>
        <div 
            style={{
                position: 'relative',
                width: '100%',
                height: '200px',
                overflow: "hidden",
                borderRadius: "20px"
            }}
            onClick={() => setLog([...log, { timestamp: Date.now(), event: "Report 1 clicked"}])}
        >
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '200px',
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: "bottom",
                    filter: "grayscale(100%)",
                }}
            />  
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: '#AD3E1B',
                opacity: "70%"
                }}
            />
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                color: 'white', 
                fontSize: '24px', 
                padding: "20px",
                textAlign: "left",
                color: "white",
                flexDirection: "column",
                width: "100%",
                justifyContent: "space-between"
                
                }}>
                    <div>
                        <p style={{ margin: 0, padding: 0, fontSize: "24px", fontWeight: "700"}}>Mortgages & Financing</p>
                        <p style={{ margin: 0, padding: 0, fontSize: "16px", fontWeight: "200"}}>Your Comprehensive Guide to Property Purchase</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <p style={{ margin: 0, padding: 0, fontSize: "24px", fontWeight: "700"}}>€12.90</p>
                        <p style={{ margin: 0, padding: 0, fontSize: "12px", fontWeight: "200", backgroundColor: "white", color: "black", height: "20px", width: "100px", textAlign: "center", borderRadius: "20px", marginTop: "8px", paddingTop: "1px"}}>Learn more</p>
                    </div>
                </div>
                    
        </div>

    </div>
    



    <div style={{ padding: "25px", overflow: "hidden", paddingTop: "0px", paddingBottom: "0px" }}>
        <div 
            style={{
                position: 'relative',
                width: '100%',
                height: '200px',
                overflow: "hidden",
                borderRadius: "20px"
            }}
            onClick={() => setLog([...log, { timestamp: Date.now(), event: "Report 2 clicked"}])}

        >
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '200px',
                    backgroundImage: `url(${backgroundImage2})`,
                    backgroundSize: 'cover',
                    backgroundPosition: "top",
                    filter: "grayscale(100%)",
                }}
            />  
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: '#1A848B',
                opacity: "70%"
                }}
            />
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                color: 'white', 
                fontSize: '24px', 
                padding: "20px",
                textAlign: "left",
                color: "white",
                flexDirection: "column",
                width: "100%",
                justifyContent: "space-between"
                
                }}>
                    <div>
                        <p style={{ margin: 0, padding: 0, fontSize: "24px", fontWeight: "700"}}>Renting in Barcelona</p>
                        <p style={{ margin: 0, padding: 0, fontSize: "16px", fontWeight: "200"}}>Navigating the Rental Landscape with Ease</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <p style={{ margin: 0, padding: 0, fontSize: "24px", fontWeight: "700"}}>€14.90</p>
                        <p style={{ margin: 0, padding: 0, fontSize: "12px", fontWeight: "200", backgroundColor: "white", color: "black", height: "20px", width: "100px", textAlign: "center", borderRadius: "20px", marginTop: "8px", paddingTop: "1px"}}>Learn more</p>
                    </div>
                </div>
                    
        </div>

    </div>



    <div style={{ padding: "25px", overflow: "hidden", paddingBottom: "0px" }}>
        <div 
            style={{
                position: 'relative',
                width: '100%',
                height: '200px',
                overflow: "hidden",
                borderRadius: "20px"
            }}
            onClick={() => setLog([...log, { timestamp: Date.now(), event: "Report 3 clicked"}])}

        >
            <div
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '200px',
                    backgroundImage: `url(${backgroundImage3})`,
                    backgroundSize: 'cover',
                    backgroundPosition: "top",
                    filter: "grayscale(100%)",
                }}
            />  
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backgroundColor: '#1A408B',
                opacity: "70%"
                }}
            />
            <div style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                display: 'flex',
                color: 'white', 
                fontSize: '24px', 
                padding: "20px",
                textAlign: "left",
                color: "white",
                flexDirection: "column",
                width: "100%",
                justifyContent: "space-between"
                
                }}>
                    <div>
                        <p style={{ margin: 0, padding: 0, fontSize: "24px", fontWeight: "700"}}>Expats' Essential</p>
                        <p style={{ margin: 0, padding: 0, fontSize: "16px", fontWeight: "200"}}>Mastering Barcelona Real Estate: A Detailled Guide</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <p style={{ margin: 0, padding: 0, fontSize: "24px", fontWeight: "700"}}>€16.90</p>
                        <p style={{ margin: 0, padding: 0, fontSize: "12px", fontWeight: "200", backgroundColor: "white", color: "black", height: "20px", width: "100px", textAlign: "center", borderRadius: "20px", marginTop: "8px", paddingTop: "1px"}}>Learn more</p>
                    </div>
                </div>
                    
        </div>

    </div>
    
       

  </div>
  );
}

export default AdditionalReports;
