import { useEffect, useState } from 'react';
import backgroundImage from '../assets/img/barcelona.png';


function CallToAction({ log, setLog, setPurchaseIntent, address, setAddress}) {

  return (
   <div style={{ backgroundColor: "#404040", borderRadius: "20px", marginTop: "-30px", zIndex: "1000", padding: "25px", paddingTop: "30px", paddingBottom: "30px", marginBottom: "0px"}}>
    <p style={{ textAlign: "left", fontSize: "24px", fontWeight: "700", color: "white"}}>Generate your report</p>
    <p style={{ textAlign: "left", fontSize: "16px", fontWeight: "300", paddingTop: "0px", color: "white"}}>Enter your address, see your customized report: Discover real estate intelligence tailored specifically for you.</p>
    <div style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <input value={address} onChange={(event) => setAddress(event.target.value)} style={{ height: "40px", borderRadius: "50px", border: "none", paddingLeft: "20px", fontSize: "16px", width: "100%", marginRight: "20px"}} />
                    <div>
                        <img 
                            src={require("../assets/img/submit_green.png")} alt="downward arrow"
                            style={{ height: "40px" }}
                            className="img pointer"
                            onClick={() => {
                                setLog([...log, { timestamp: Date.now(), event: "Call To Action 2 Search Attempt" }]);
                                setPurchaseIntent(true);
                            }}
                            />
                    </div>
                </div>
                <p style={{ fontSize: "10px", marginTop: "5px", fontWeight: "200", color: "white", textAlign: "left"}}>*Address submission implies agreement to our <a style={{ color: "white"}} href="https://inmuebledata360.com/terms-and-conditions">Terms and Conditions.</a></p>
                <p style={{ color: "white", fontSize: "24px", textAlign: "left", paddingTop: "30px", marginBottom: "0px"}}>€9.90 - 19.90</p>
                <p style={{ color: "white", fontSize: "12px", textAlign: "left", paddingBottom: "30px" }}>*Depends on estimated property value</p>
                
            </div>
            
           
       
    </div>
  );
}

export default CallToAction;


//import backgroundImage from '../assets/img/barcelona.png';