

import { useEffect, useState } from 'react';
  
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import backgroundImage from '../assets/img/barcelona.png';


function Hero({ log, setLog, setPurchaseIntent, address, setAddress}) {




    




  return (
   <div>
    <div style={{
          position: 'relative',
          width: '100%',
          height: '500px',
          borderRadius: "20px",
          overflow: "hidden",
          paddingBottom: "60px",
        }}
    >
        <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: "center",
          filter: "grayscale(100%)"
        }}/>
        <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          backgroundColor: '#2F6526',
          opacity: "70%"
        }}/>
        <div style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          color: 'white', 
          fontSize: '24px', 
          padding: "20px",
          textAlign: "left",
          color: "white",
          flexDirection: "column",
          width: "100%",
          justifyContent: "space-between"
        }}>
            <div>
                <p style={{ fontWeight: "200", fontSize: "16px", margin: 0, padding: 0, lineHeight: "16px", paddingBottom: "10px"}}>Properties in Barcelona</p>
                <p style={{ fontWeight: "700", fontSize: "32px", margin: 0, padding: 0, lineHeight: "32px", paddingBottom: "10px"}}>Personalised Reports </p>
                <p style={{ fontWeight: "200", fontSize: "24px", margin: 0, padding: 0, lineHeight: "28px"}}>Find out detailed information about a <span style={{ fontWeight: "700"}}>specific</span> property</p>
            
                
            </div>
            <div style={{ marginBottom: "20px" }}>
                <p style={{ fontSize: "24px", fontWeight: "700", margin: 0, padding: 0, marginBottom: "10px"}}>Get a personalised report</p>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <input value={address} onChange={(event) => setAddress(event.target.value)} style={{ height: "40px", borderRadius: "50px", border: "none", paddingLeft: "20px", fontSize: "16px", width: "100%", marginRight: "20px"}} />
                    <div>
                        <img 
                            src={require("../assets/img/submit.png")} alt="downward arrow"
                            style={{ height: "40px" }}
                            className="img pointer"
                            onClick={()=> {
                                setLog([...log, { timestamp: Date.now(), event: "Call To Action in Hero Search Attempt with address:", address }]);
                                setPurchaseIntent(true)
                            }}
                        />
                    </div>
                </div>
                <p style={{ fontSize: "10px", marginTop: "5px", fontWeight: "200"}}>*Address submission implies agreement to our <a style={{ color: "white"}} href="https://inmuebledata360.com/terms-and-conditions">Terms and Conditions.</a></p>

                
            </div>
        </div>
    </div>
    
  
       
   </div>
  );
}

export default Hero;
