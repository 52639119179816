import { useEffect, useState, useRef } from 'react';
import { initializeApp } from "firebase/app";
import './App.css';

import Navigation from "./components/Navigation";
import Hero from "./components/Hero";
import ReportDetails from "./components/ReportDetails";
import CookieOverlay from './components/CookieOverlay';
import CallToAction from './components/CallToAction';
import Services from './components/Services';
import CallToAction2 from './components/CallToAction2';
import AdditionalReports from './components/AdditionalReports';
import Faq from './components/Faq';
import {db} from './firebase-config';
import Purchase from './components/Purchase';


function App() {
  const [hasConsent, setHasConsent] = useState(true);
  const [log, setLog] = useState([]);
  const [sessionId, setSessionId] = useState("");
  const [purchaseIntent, setPurchaseIntent] = useState(false);
  const logRef = useRef(log); 
  let scrollEndTimer = null;
  const [address, setAddress] = useState("");

  useEffect(() => {
    logRef.current = log; // Update ref's current value whenever log changes
}, [log]);

  useEffect(() => {
    
    const sessionID = generateRandomID(20);
    setSessionId(sessionID);
    //db.collection("session").doc(""+sessionID).set({})
  }, []); 

  useEffect(() => {
    console.log("Log length: ", log.length)
    if (log.length > 0) {
        //db.collection("session").doc(sessionId).set({log})
        console.log("An item was added!", log);
        // Perform any action here when an item is added
    }
  }, [log.length]); // Dependency on the array's length



  useEffect(() => {
    // Check if consent is already given
    const consent = localStorage.getItem('bannerShown');
    if (consent === "true") {
     

    }
    setHasConsent(consent === "true");
  }, []);

  const handleConsent = (consentGiven) => {
    localStorage.setItem('bannerShown', consentGiven);
    setHasConsent(true);
    if (consentGiven) {
      console.log("Starting analytics...");
      
    }
  };

  const generateRandomID = (length) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    
    return result;
}


  const handleScroll = () => {
    clearTimeout(scrollEndTimer);
    
    scrollEndTimer = setTimeout(() => {
        const scrollPosition = window.scrollY;

        const newLog = [
            ...logRef.current, // Use current log from ref
            {
                timestamp: Date.now(),
                event: `Scroll Position End Scroll: ${scrollPosition}`
            }
        ];
        setLog(newLog);
    }, 150); // Adjust delay as needed
};

  useEffect(() => {
    window.addEventListener('scrollend', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
        window.removeEventListener('scrollend', handleScroll);
    };
  }, []);

  return (
    <div className="App" style={{ maxWidth: "600px", margin: "0 auto"}}>
      {
        (!hasConsent) ? <CookieOverlay handleConsent={handleConsent}/> : null
      }
      {
        (!purchaseIntent) ?
        <div>
          <Navigation />
          <Hero address={address} setAddress={setAddress} log={log} setLog={setLog} setPurchaseIntent={setPurchaseIntent}/>
          <ReportDetails />
          <CallToAction address={address} setAddress={setAddress} />
          <Services />
          <CallToAction2 address={address} setAddress={setAddress} log={log} setLog={setLog} setPurchaseIntent={setPurchaseIntent}/>
          <AdditionalReports log={log} setLog={setLog}/>
          <Faq />
          <div style={{width: "100vw", height: "1000px"}}/>
        </div>
        : 
        <Purchase address={address}/>
      
      }
      
      
      <p className="d-none d-lg-block" style={{ position: "fixed", bottom: "0px", right: "20px", color: "lightgrey", fontSize: "8px"}}>v0.0.1</p>
    </div>
  );
}

export default App;

