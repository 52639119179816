import '../App.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import backgroundImage from '../assets/img/barcelona.png';



function Purchase({ address }) {
    const [language, setLanguage] = useState("english");
    let { lang } = useParams();

    useEffect(() => {
        setLanguage(lang);
      }, [lang]); 


  return (
   <div style={{ backgroundColor: "#404040"}}>
     <div className="d-block d-lg-none">
      <div  style={{ width: "100%", display: "flex", flexDirection: "row",justifyContent: "space-between" }}>
        <div style={{ display: "flex", flexDirection: "row", height: "75px"}}>
          <img 
            src={require("../assets/img/inverted_logo.png")} alt="downward arrow"
            style={{ height: "35px", margin: "20px 10px 0px 20px", border: "0.5px white solid", borderRadius: "20px"}}
            className="img pointer"
            onClick={()=> window.open("https://www.linkedin.com/pulse/four-reasons-should-consider-using-video-cvs-idorenyin-antiaobong/", "_blank")}
          />
          <div style={{ textAlign: "left", margin: "auto"}}>
            <p style={{ margin: 0, padding: 0, lineHeight: "14px", fontSize: "14px", color: "white"}}>
              InmuebleData
            </p>
            <p style={{ margin: 0, padding: 0, lineHeight: "14px", fontSize: "14px", color: "white"}}>
              360.com
            </p>
          </div>
         </div>
        
        
          <div style={{ display: "flex", flexDirection: "row", margin: "20px"}}>
            <div 
              style={{ color: "white", textAlign: "center", width: "35px", height: "35px", border: (language === "es") ? "0.5px white solid" : null , borderRadius: "20px"}}
              onClick={() => setLanguage("es")}
            >
              <p style={{ fontSize: "14px", margin: "auto", paddingTop: "7px" }}>ES</p>
            </div>
            <div 
              style={{ color: "white", textAlign: "center", width: "35px", height: "35px", border: (language === "en") ? "0.5px white solid" : null , borderRadius: "20px"}}
              onClick={() => setLanguage("en")}
            >
              <p style={{ fontSize: "14px", margin: "auto", paddingTop: "7px" }}>EN</p>
            </div>
            
            
          </div>
    
     
    
      </div>
      
       
      </div>
        <p style={{ margin: 0, padding: "20px 10px 0px 25px", color: "white", fontSize: "24px", fontWeight: "700", textAlign: "left" }}>Your personalised report for:</p>
        <p style={{ margin: 0, padding: "0 10px 0px 25px", color: "white", fontSize: "24px", fontWeight: "200", textAlign: "left", paddingLeft: "25px", paddingRight: "10px"}}>{address}</p>
        <div>
                <div style={{ backgroundColor: "white", width: "220px", height: "340px", margin: "50px auto",  }}>
                <p style={{ position: "relative", top: "2px", right: "2px", fontSize: "2px", textAlign: "right", width: "100%", paddingRight: "10px", paddingTop: "10px"}}>InmuebleData360.com - January 2024</p>
                <div style={{ width: "100%", textAlign: "left"}}>
                    <img 
                        src={require("../assets/img/logo_id360.png")} alt="downward arrow"
                        style={{ height: "25px", border: "0.5px black solid", borderRadius: "20px", marginLeft: "15px"}}
                        className="img pointer"
                        onClick={()=> window.open("https://www.linkedin.com/pulse/four-reasons-should-consider-using-video-cvs-idorenyin-antiaobong/", "_blank")}
                    />
                </div>
                <p style={{ fontWeight: "200", fontSize: "10px", textAlign: "left", paddingLeft: "15px", paddingTop: "40px", marginBottom: "0px"}}>Property Data Report Barcelona</p>
                <p style={{ fontWeight: "700", fontSize: "12px", textAlign: "left", paddingLeft: "15px", whiteSpace: "nowrap", overflow: "hidden", marginRight: "10px" }}>{address}</p>
                <div style={{
                    position: 'relative',
                    width: '100%',
                    height: '150px',
                   
                    overflow: "hidden",
                    
                    
                }}>
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '150px',
                            backgroundImage: `url(${backgroundImage})`,
                            backgroundSize: 'cover',
                            backgroundPosition: "top",
                            filter: "grayscale(100%)"
                        }}
                    />  
                    <div style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#2F6526',
                        opacity: "70%"
                        }}
                    />
                </div>
                <p style={{ margin: 0, padding: 0, fontSize: "2px", fontWeight: "700", textAlign: "left", width: "100%", marginLeft: "10px", marginTop: "20px"}}>Disclaimer</p>
                <p style={{ margin: 0, padding: 0, fontSize: "2px", fontWeight: "200", textAlign: "left", width: "100%", marginLeft: "10px", marginTop: "0px"}}>This is an example report and is just intended to visualise the product </p>

            </div>
        </div>
        <p style={{ margin: 0, padding: "20px 10px 0px 25px", color: "white", fontSize: "24px", fontWeight: "700", textAlign: "left" }}>€12.90 <span style={{ fontWeight: "200"}}>(incl. taxes)</span></p>
        <p style={{ margin: 0, padding: "0px 10px 0px 25px", color: "white", fontSize: "12px", fontWeight: "20", textAlign: "left" }}>Debit Card, Credit Card, Paypal, Klarna</p>
        <div style={{ backgroundColor: "white", marginLeft: "25px", marginRight: "25px",  borderRadius: "100px"}}>
            <p style={{ margin: "10px 0px 0px", padding: "5px 0px", color: "black", fontSize: "24px", fontWeight: "700", textAlign: "center" }}>Pay now</p>

        </div>
        <p style={{ margin: 0, padding: "40px 10px 0px 25px", color: "white", fontSize: "16px", fontWeight: "700", textAlign: "left" }}>Following data points are included:</p>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px 20px 0px 25px"}}>
            <img 
                src={require("../assets/img/check.png")} alt="downward arrow"
                style={{ height: "30px", marginTop: "3px"}}
                className="img pointer"
            />
            <div style={{ flexDirection: "column"}}>
                <p style={{ margin: 0, padding: "0px 10px 0px 10px", color: "white", fontSize: "16px", fontWeight: "200", textAlign: "left" }}>Price per m2 for buying and renting</p>
                <p style={{ margin: 0, padding: "0px 10px 0px 10px", color: "white", fontSize: "10px", fontWeight: "200", textAlign: "left" }}>We provide you with the per square meter pricing for both buying and renting from 2014 to 2023 for your specific locality within your neighborhood, along with comparative data for the entire neighborhood and Barcelona, enabling you to benchmark effectively.</p>

            </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px 20px 0px 25px"}}>
            <img 
                src={require("../assets/img/check.png")} alt="downward arrow"
                style={{ height: "30px", marginTop: "3px"}}
                className="img pointer"
            />
            <div style={{ flexDirection: "column"}}>
                <p style={{ margin: 0, padding: "0px 10px 0px 10px", color: "white", fontSize: "16px", fontWeight: "200", textAlign: "left" }}>Bars, restaurants etc. in the area</p>
                <p style={{ margin: 0, padding: "0px 10px 0px 10px", color: "white", fontSize: "10px", fontWeight: "200", textAlign: "left" }}>Our comprehensive report includes a detailed overview of the dining and entertainment landscape in your specific area. We cover the variety and density of bars, restaurants, cafes, and other leisure venues within your specific locality and neighborhood. You'll also receive comparative insights for Barcelona as a whole, giving you a clear picture of the social and culinary scene around your property.</p>

            </div>
        </div>


        <div style={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px 20px 0px 25px"}}>
            <img 
                src={require("../assets/img/check.png")} alt="downward arrow"
                style={{ height: "30px", marginTop: "3px"}}
                className="img pointer"
            />
            <div style={{ flexDirection: "column"}}>
                <p style={{ margin: 0, padding: "0px 10px 0px 10px", color: "white", fontSize: "16px", fontWeight: "200", textAlign: "left" }}>Noise pollution</p>
                <p style={{ margin: 0, padding: "0px 10px 0px 10px", color: "white", fontSize: "10px", fontWeight: "200", textAlign: "left" }}>Our report provides an in-depth analysis of noise pollution levels on your specific street.</p>

            </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px 20px 0px 25px"}}>
            <img 
                src={require("../assets/img/check.png")} alt="downward arrow"
                style={{ height: "30px", marginTop: "3px"}}
                className="img pointer"
            />
            <div style={{ flexDirection: "column"}}>
                <p style={{ margin: 0, padding: "0px 10px 0px 10px", color: "white", fontSize: "16px", fontWeight: "200", textAlign: "left" }}>Crime rates</p>
                <p style={{ margin: 0, padding: "0px 10px 0px 10px", color: "white", fontSize: "10px", fontWeight: "200", textAlign: "left" }}>Our report includes a concise overview of crime rates in your specific area and across Barcelona.</p>

            </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px 20px 0px 25px"}}>
            <img 
                src={require("../assets/img/check.png")} alt="downward arrow"
                style={{ height: "30px", marginTop: "3px"}}
                className="img pointer"
            />
            <div style={{ flexDirection: "column"}}>
                <p style={{ margin: 0, padding: "0px 10px 0px 10px", color: "white", fontSize: "16px", fontWeight: "200", textAlign: "left" }}>Detailed economic data for the area</p>
                <p style={{ margin: 0, padding: "0px 10px 0px 10px", color: "white", fontSize: "10px", fontWeight: "200", textAlign: "left" }}>Our report provides detailed economic data for your area, encompassing key metrics such as the average income and taxes paid by residents, the unemployment rate, and the average occupancy of flats. It also delves into demographic insights, including the average age of residents in the neighborhood, offering a comprehensive understanding of the local economic and social landscape.</p>

            </div>
        </div>

        <div style={{ display: "flex", flexDirection: "row", width: "100%", padding: "10px 20px 0px 25px"}}>
            <img 
                src={require("../assets/img/check.png")} alt="downward arrow"
                style={{ height: "30px", marginTop: "3px"}}
                className="img pointer"
            />
            <div style={{ flexDirection: "column"}}>
                <p style={{ margin: 0, padding: "0px 10px 0px 10px", color: "white", fontSize: "16px", fontWeight: "200", textAlign: "left" }}>Market trends affecting this property</p>
                <p style={{ margin: 0, padding: "0px 10px 0px 10px", color: "white", fontSize: "10px", fontWeight: "200", textAlign: "left" }}>Our report delivers a nuanced analysis of market trends affecting your specific property, drawing on the insights of industry experts. We break down these trends to provide a clear understanding of how they directly impact the value and potential of your property of interest, ensuring you are informed by the latest expert perspectives.</p>

            </div>
        </div>

        <div style={{ width: "100%", height: "2000px"}}/>        

   </div>

  );
}

export default Purchase;

