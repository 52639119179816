import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';



function TermsOfUse() {

  return (
   <>
        <Container>
            <div style={{ maxWidth: "680px", margin: "0 auto", marginTop: "100px"}}>
                <h1 style={{ padding: "20px 0px"}}>Terms of Use for InmuebleData360</h1>

                <h2 style={{ padding: "20px 0px"}}>1. Acceptance</h2>
                <p>
                    When you create an account, watch videos, make a purchase, download our software, or use our Services in any other way, you agree to this Agreement and give your consent to enter into a contract with us electronically.
                </p>
                <p>
                    If you are using our Services on behalf of an entity other than an individual, the person who registers the account or uses our Services must be authorized to bind the entity. In this context, "you" refers to both the entity itself and each individual who has the authority to access the account.
                </p>
                <p>
                    We may modify this Agreement by posting an updated version on our website. Your continued use of our Services after such changes constitutes your acceptance of the revised Agreement.
                </p>
                <p>
                    This Agreement includes our Privacy Policy and the addenda listed in Section 12. We encourage you to review our Privacy Policy to learn about the information we collect, how we use it, and with whom we share it.
                </p>

<h2 style={{ padding: "20px 0px"}}>2. Our Services</h2>

<h3 style={{ padding: "20px 0px"}}>Service License</h3>
<p>
    In accordance with these terms, we grant you access to our Services, including the right to:
    <ul>
        <li>Stream videos that you are authorized to view;</li>
        <li>Upload, store, and/or live stream videos, depending on your subscription plan;</li>
        <li>Embed our video player in third-party websites; and</li>
        <li>Utilize all related functionalities that we may offer.</li>
    </ul>
</p>

    <h4 style={{ padding: "20px 0px"}}>Features</h4>
    <p>
        The features available to you are based on your chosen plan. We may modify these features periodically. For paid accounts, we guarantee to provide the fundamental video hosting and streaming features of your plan, including bandwidth and storage capacities, as specified at the time of your purchase, for the duration of your current service period.
    </p>

    <h4 style={{ padding: "20px 0px"}}>Bandwidth</h4>
    <p>
        The bandwidth allowance for your Self-Serve plan, detailed in Section 4, covers your total usage across all your Self-Serve accounts. Circumventing bandwidth limits by creating multiple accounts is prohibited. Exceeding your bandwidth allowance may lead to additional charges, a required plan upgrade, or account termination, following advance written notice. For more details on bandwidth, refer to our Bandwidth article.
    </p>

    <h4 style={{ padding: "20px 0px"}}>Beta Services</h4>
    <p>
        You may be invited to use beta, pilot, or limited release features (“Beta Services”). By using any Beta Services, you acknowledge that they may be experimental, subject to change or discontinuation, may contain errors, and are not bound by service commitments. You agree to provide timely feedback and use Beta Services solely for feedback purposes.
    </p>

    <h4 style={{ padding: "20px 0px"}}>Experiments</h4>
    <p>
        We may conduct experiments or offer certain elements of our Services, including promotions, features, advertisements, user interfaces, plans, and pricing, at our sole discretion. These may be subject to additional terms within our Services and might not be available to you.
    </p>

    <h4 style={{ padding: "20px 0px"}}>Third Parties</h4>
    <p>
        Our Services may include links to and integrations with websites or services operated by third parties. Your use of such websites or services is subject to their respective terms of service and our Third-Party Service Addendum.
    </p>

    <h2 style={{ padding: "20px 0px"}}>3. Accounts</h2>

    <h3 style={{ padding: "20px 0px"}}>Registration</h3>
    <p>
        To access specific features (e.g., uploading or live streaming videos), you may create an account by providing an email address. Upon creating an account, you consent to receive communications from InmuebleData360 at this email address, including outside of standard hours (before 8:00 a.m. and after 9:00 p.m. local time). It is imperative to maintain an accurate and current email address for effective communication.
    </p>


    <h3 style={{ padding: "20px 0px"}}>Age Requirements</h3>
    <p>
        Users must be at least 18 years old or the age of majority in their jurisdiction, whichever is higher, to create an account or use our Services. Commercial use of the Services requires a minimum age of 18 years. Minors may use our Services only through an account owned by a parent or legal guardian and with their active involvement. We advise the parent or guardian to review and consent to this Agreement before allowing a minor to proceed.
    </p>

    <h3 style={{ padding: "20px 0px"}}>Parents and Guardians</h3>
    <p>
        By permitting your child to use the Services via your account, you acknowledge your responsibility for monitoring and supervising their usage. If you suspect unauthorized use of your account by your child without your consent, contact us immediately for access revocation.
    </p>

    <h3 style={{ padding: "20px 0px"}}>Account Security</h3>
    <p>
        You bear responsibility for all activities conducted through your account, including any unauthorized use. Keep your account credentials confidential and do not share them with others. If using a shared computer, always log out after each session. In case of unauthorized access to your account, change your password and inform us immediately.
    </p>

    <h2 style={{ padding: "20px 0px"}}>4. Subscription Plans</h2>

    <h3 style={{ padding: "20px 0px"}}>Plan Types</h3>
    <p>
        InmuebleData360 offers paid subscriptions for uploading and sharing video content. Note that advertised prices and features are subject to change.
    </p>

    <h3 style={{ padding: "20px 0px"}}>Fees</h3>
    <p>
        All fees, along with any applicable taxes, must be paid during your subscription and any subsequent renewals. Fees might include a fixed weekly charge and variable charges based on transactions or usage. Fee adjustments may occur, and we aim to notify you before your next billing cycle. If you disagree with a fee change, you may cancel your subscription in line with this Agreement.
    </p>

    <h3 style={{ padding: "20px 0px"}}>Free Trials and Discounts</h3>
    <p>
        We may offer free trials or discounted subscriptions. Post-free trial, your paid subscription commences (unless cancelled before the trial’s end) and the full weekly fee applies. For discounted initial subscriptions, renewal will be at the standard rate.
    </p>

    <h3 style={{ padding: "20px 0px"}}>Refund Policy</h3>
    <p>
        Self-Serve subscribers purchasing directly from InmuebleData360 may cancel and receive a full refund within 1 day for a weekly plan, subject to conditions. Exceptions to our refund policy include, but are not limited to:
        <ul>
            <li>In-app purchases;</li>
            <li>Fees post-free trial;</li>
            <li>Abuse of refund policy;</li>
            <li>Subscription renewals or plan changes;</li>
            <li>Non-subscription fees;</li>
            <li>Delayed refund requests;</li>
            <li>Accounts violating this Agreement;</li>
            <li>Promotion-related exclusions;</li>
            <li>Chargeback disputes; and</li>
            <li>Enterprise plan customers.</li>
        </ul>
    </p>

    <h3 style={{ padding: "20px 0px"}}>Automatic Renewal</h3>
    <p>
        Self-Serve subscriptions automatically renew unless cancelled at least one day before the renewal date. Weekly plans renew every 7 days. Payment is required at the start of each renewal period. Unused resources do not carry over.
    </p>

    <h3 style={{ padding: "20px 0px"}}>How to Cancel Renewal</h3>
    <p>
        Self-Serve subscribers can cancel automatic renewal in their Billing Settings. Cancellations or non-renewals won’t affect the current subscription period. InmuebleData360 reserves the right to decline renewals.
    </p>

    <h3 style={{ padding: "20px 0px"}}>Lapse Policy</h3>
    <p>
        Upon subscription end, accounts may revert to a free membership or be deleted, at InmuebleData360's discretion. Any content may be removed to align with new account limits. You are responsible for backing up your content. InmuebleData360 is not liable for content loss. Additional guidelines on lapsed subscriptions may be published, outlining current practices without obligating InmuebleData360 to maintain post-subscription account status.
    </p>

    <h3 style={{ padding: "20px 0px"}}>Resale</h3>
    <p>
        Reselling, renting, leasing, or distributing any plan or Service aspect to third parties without written authorization from InmuebleData360 is prohibited. Accounts sold through authorized resellers may be suspended or terminated for non-payment or agreement violations.
    </p>
    <h2 style={{ padding: "20px 0px"}}>5. Acceptable Use Policy</h2>
<p>
    You may be permitted to create, upload, live stream, submit, or publish content such as videos, recordings, images, text, and fonts (collectively, "content"). “Content” encompasses all material you submit to InmuebleData360, including those submitted prior to the last update of this Agreement. It is imperative that your content, and your conduct, adhere to the Acceptable Use Policy outlined in this Section 5. InmuebleData360 may (but is not required to) monitor your account, content, and conduct, irrespective of your privacy settings. InmuebleData360 reserves the right to remove or restrict access to any content or account deemed to be in violation of this Acceptable Use Policy.
</p>

<h3 style={{ padding: "20px 0px"}}>5.1 Copyright Policy</h3>
<p>
    Content uploaded should be rightfully yours. Copyright owners may notify InmuebleData360 of potential copyright infringements as per our Copyright Policy. Accounts repeatedly infringing on copyright may be terminated.
</p>

<h3 style={{ padding: "20px 0px"}}>5.2 Content Restrictions</h3>
<p>
    Prohibited content includes, but is not limited to, content that:
    <ul>
        <li>Infringes any third party’s rights (copyrights, trademarks, privacy, etc.);</li>
        <li>Is sexually explicit or promotes sexual services;</li>
        <li>Is defamatory, harassing, or abusive;</li>
        <li>Contains hate speech or discriminates;</li>
        <li>Supports terrorism or hate groups;</li>
        <li>Provides instructions for making weapons or harmful devices;</li>
        <li>Exploits or harms minors;</li>
        <li>Depicts or encourages self-harm or suicide;</li>
        <li>Shows extreme violence, brutality, sexualized violence, animal cruelty;</li>
        <li>Promotes fraudulent schemes, unlawful transactions, or deceptive marketing;</li>
        <li>Spreads false information about vaccination, health, voting, or seeks to obstruct voting;</li>
        <li>Presents misinformation regarding real-world tragedies, crimes, or poses a serious risk of harm;</li>
        <li>Violates any applicable laws.</li>
    </ul>
</p>
<h2 style={{ padding: "20px 0px"}}>5.3 Code of Conduct</h2>
<p>
    When using InmuebleData360's Services, the following are strictly prohibited:
    <ul>
        <li>Choosing offensive screen names or avatars, such as those with explicit language or nudity;</li>
        <li>Behaving deceptively or impersonating any individual or organization;</li>
        <li>Harassing or stalking any person;</li>
        <li>Exploiting or harming minors in any way;</li>
        <li>Distributing spam or employing misleading metadata;</li>
        <li>Gathering personal information about others without their explicit consent;</li>
        <li>Accessing another user’s account, unless explicitly permitted;</li>
        <li>Using or exporting InmuebleData360's Services in contravention of any U.S. laws (noting that InmuebleData360 operates in Barcelona, Spain, local laws may also apply);</li>
        <li>Acting in a manner that could subject InmuebleData360 to specific privacy regulations unless previously agreed in writing;</li>
        <li>Engaging in any form of unlawful activity;</li>
        <li>Embedding InmuebleData360's video player on or linking to sites with content that violates Section 5.2;</li>
        <li>Encouraging or causing others to engage in any of the prohibited activities above.</li>
    </ul>
</p>

<h2 style={{ padding: "20px 0px"}}>5.4 Prohibited Technical Measures</h2>
<p>
    You are not allowed to:
    <ul>
        <li>Scrape, reproduce, redistribute, frame, mirror, create derivative works from, decompile, reverse engineer, alter, archive, or disassemble any part of InmuebleData360's Services, or attempt to bypass any security or content protection measures, except as authorized by law or explicitly permitted by InmuebleData360 in writing;</li>
        <li>Remove or modify any logos, watermarks, or proprietary rights notices in InmuebleData360's Services or outputs without permission;</li>
        <li>Introduce any malicious software, scripts, or code;</li>
        <li>Overload InmuebleData360's servers with unreasonable requests; or</li>
        <li>Engage in any actions that manipulate, interfere with, or damage InmuebleData360's Services.</li>
    </ul>
</p>

<h2 style={{ padding: "20px 0px"}}>5.5 Restricted Users</h2>
<p>
    The use of InmuebleData360's Services is not permitted for:
    <ul>
        <li>Individuals or groups associated with terror or hate activities;</li>
        <li>Entities identified by U.S. agencies as prohibited for contracts (e.g., Specially Designated Nationals); or</li>
        <li>Persons under sanctions by non-U.S. governments, affecting InmuebleData360's ability to provide service across numerous countries.</li>
    </ul>
    In compliance with U.S. sanctions, purchasing goods or software services from InmuebleData360 under these conditions is prohibited. This is a continuous representation of your compliance with these restrictions.
</p>
<p>
    Users may filter videos based on user-defined content ratings. While InmuebleData360 endeavors to ensure accurate ratings, we cannot guarantee the appropriateness of ratings provided by others. You are required to rate your videos accurately.
</p>

<h2 style={{ padding: "20px 0px"}}>6. Licenses Granted by You</h2>
<p>
    As between you and InmuebleData360, you retain ownership of all intellectual property rights in your submitted content. To enable InmuebleData360 to host and stream your content, you grant us the permissions detailed below.
</p>

<h3 style={{ padding: "20px 0px"}}>6.1 Your Video Content</h3>
<p>
    By submitting a video, you grant InmuebleData360 permission to:
    <ul>
        <li>Stream the video to end users;</li>
        <li>Embed the video on third-party websites;</li>
        <li>Distribute the video through our APIs;</li>
        <li>Make the video available for download;</li>
        <li>Transcode the video for optimized streaming;</li>
        <li>Generate and display thumbnails from your video;</li>
        <li>Automatically generate and display summaries, chapters, closed captions, and subtitles; and</li>
        <li>Alter or enhance your video as per your instructions.</li>
    </ul>
    Distribution will adhere to your specified privacy settings. By granting third-party access, you allow them to stream (and/or download or embed, where applicable) your video. This license includes video metadata such as title, description, tags, etc.
</p>
<p>
    The license begins upon submission and ends upon deletion by you or InmuebleData360. However, InmuebleData360 may retain archival copies under certain conditions such as restoration requests, legal claims, or legal obligations.
</p>

<h3 style={{ padding: "20px 0px"}}>6.2 InmuebleData360 Create Content</h3>
<p>
    Content submitted for video creation using InmuebleData360 Create, and the resulting videos, are governed by our InmuebleData360 Create Addendum.
</p>

<h3 style={{ padding: "20px 0px"}}>6.3 Account Profile</h3>
<p>
    You grant InmuebleData360 permission to use your name, likeness, trademarks, logos, or other identifiers in your account profile for public display or to specified audiences. You can revoke this permission by deleting your account. InmuebleData360 may use public profiles in its marketing and investor materials.
</p>

<h3 style={{ padding: "20px 0px"}}>6.4 Feedback</h3>
<p>
    Any feedback you provide about our Services can be used, copied, transmitted, and displayed by InmuebleData360 without compensation to you.
</p>

<h3 style={{ padding: "20px 0px"}}>6.5 Scope of Licenses</h3>
<p>
    All licenses granted by you are non-exclusive, worldwide, royalty-free, and include necessary rights for InmuebleData360 to exercise its rights and fulfill its obligations. By granting these licenses, you waive any moral rights you may hold. Any breach of terms by InmuebleData360 results in a breach of contract claim, not a license condition violation. These licenses are additional to any others you may grant.
</p>

<h2 style={{ padding: "20px 0px"}}>7. Your Obligations</h2>

<h3 style={{ padding: "20px 0px"}}>7.1 Representations and Warranties</h3>
<p>
    For each content piece submitted, you represent and warrant that:
    <ul>
        <li>You have the right to submit it and grant the licenses stated;</li>
        <li>No third-party licenses or royalties are required for its streaming or distribution;</li>
        <li>All necessary releases from individuals in the content have been obtained;</li>
        <li>The content does not infringe on any third-party rights, including intellectual property, publicity, moral, and privacy rights; and</li>
        <li>The content adheres to this Agreement and applicable laws.</li>
    </ul>
</p>

<h3 style={{ padding: "20px 0px"}}>7.2 Indemnification</h3>
<p>
    You will indemnify and hold harmless InmuebleData360 against all third-party claims and liabilities arising from your submitted content or actions that, if proven true, would breach this Agreement.
</p>
<h2 style={{ padding: "20px 0px"}}>8. Term and Termination</h2>
<p>
    This Agreement commences upon your first use of our Services and remains in effect as long as you use our Service or maintain an account with us, whichever is longer. Paid accounts persist for the subscribed period and renew as per Section 4. For free users, (a) InmuebleData360 may terminate this Agreement by providing thirty (30) days’ written notice, and (b) users can terminate at any time by deleting their accounts.
</p>
<p>
    In case of a breach of this Agreement by you, InmuebleData360 may: (a) terminate this Agreement immediately with or without prior written notice; (b) suspend, delete, or restrict access to your account or content; and (c) retain any payable amounts to you, which you forfeit, as allowed by law. Re-registration is not permitted if your account is terminated due to a breach.
</p>
<p>
    Upon termination or expiration, certain sections will survive: Section 6.4 (Feedback), Section 7.2 (Indemnification), Section 9 (Disclaimers), Section 10 (Limitation of Liability), Section 11 (Disputes, Arbitration, and Choice of Law), and Section 12 (General Provisions).
</p>

<h2 style={{ padding: "20px 0px"}}>9. Disclaimers</h2>
<p>
    InmuebleData360 PROVIDES SERVICES "AS IS" AND "AS AVAILABLE," WITHOUT ANY WARRANTY. Use of the Services is at your own risk. You are responsible for your device and internet access.
</p>
<p>
    EXCEPT AS STATED IN THIS AGREEMENT, TO THE EXTENT PERMITTED BY LAW, InmuebleData360 DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. InmuebleData360 does not guarantee:
    <ul>
        <li>Availability or permission of Services in your jurisdiction, uninterrupted or error-free service, complete security, or device/browser compatibility;</li>
        <li>Hosting, availability, or removal of any specific content;</li>
        <li>Content submitted by or actions of users;</li>
        <li>Effectiveness of geo-filtering or digital rights management;</li>
        <li>Compliance with specific privacy laws like HIPAA or GLBA;</li>
        <li>That our Services will meet all business or professional needs;</li>
        <li>Continued support of any particular feature or backwards compatibility with third-party software or devices; or</li>
        <li>Regarding third-party websites and resources.</li>
    </ul>
</p>
<h2 style={{ padding: "20px 0px"}}>10. Limitation of Liability</h2>
<p>
    TO THE EXTENT PERMITTED BY APPLICABLE LAW: (A) InmuebleData360 SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF BUSINESS, PROFITS, GOODWILL, DATA, OR OTHER INTANGIBLE LOSSES, EVEN IF InmuebleData360 HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES; AND (B) InmuebleData360'S TOTAL LIABILITY TO YOU, EXCEPT FOR InmuebleData360’S CONTRACTUAL PAYMENT OBLIGATIONS HEREUNDER (IF ANY), SHALL NOT EXCEED THE AMOUNTS PAID BY YOU TO InmuebleData360 OVER THE TWELVE (12) MONTHS PRECEDING YOUR CLAIM(S) OR ONE HUNDRED DOLLARS (USD $100), WHICHEVER IS GREATER.
</p>

<h2 style={{ padding: "20px 0px"}}>11. Disputes, Arbitration, and Choice of Law</h2>
<p>
    If you have issues with our Services, please contact us first to attempt resolution without outside assistance.
</p>

<h3 style={{ padding: "20px 0px"}}>11.1 Choice of Law</h3>
<p>
    This Agreement and any disputes arising from or relating to your use of our Services will be governed and interpreted in accordance with the laws of Spain, without regard to its conflict of law principles. Specifically, InmuebleData360, being based in Barcelona, Spain, falls under the jurisdiction of Spanish law. Any legal actions or proceedings related to this Agreement or your use of our Services shall be exclusively subject to the jurisdiction of the courts located in Barcelona, Spain. By using our Services, you consent to this jurisdiction and waive any objections to such jurisdiction or venue.
</p>

<h3 style={{ padding: "20px 0px"}}>11.2 Arbitration of Privacy Claims; Class Action Waiver</h3>
<p>
    Privacy disputes ("Covered Privacy Claim") will be resolved exclusively by binding arbitration.
</p>

<h2 style={{ padding: "20px 0px"}}>12. General Provisions</h2>

<p>
    Vimeo reserves all rights not expressly granted. Rights and remedies are cumulative, and no failure or delay in exercising a right waives further exercise. If any term is invalid or unenforceable, it will be limited or severed as needed.
</p>

<p>
    Force Majeure: InmuebleData360 is not liable for delays or failures caused by uncontrollable events like natural disasters, pandemics, terrorist attacks, war, government orders, or other unforeseeable circumstances.
</p>

<p>
    Relationship: These Terms don't create an agency, partnership, joint venture, employment, or franchise relationship. The Agreement binds successors and permitted assigns. Assignments by you without consent are void. InmuebleData360 can assign these Terms without consent. Third parties gain no rights from this Agreement.
</p>

<p>
    Entire Agreement: This Agreement is the full understanding of the parties and supersedes prior agreements on this subject. It can only be modified as stated in Section 1 or a signed document by InmuebleData360. Signed agreements with conflicting terms will prevail over this Agreement for that subject matter.
</p>



            </div>

        </Container>
   
       
   </>
  );
}

export default TermsOfUse;
